// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Image from "components/layout/Image";

// #####################################################

export const StyledImage = styled(Image)`
	vertical-align: text-bottom;
	margin-bottom: 0.125rem;
	height: ${({ height }) => height}px;

	${(props) =>
		(props.marginBottom || props.marginBottom === 0) &&
		css`
			margin-bottom: ${({ marginBottom }) => marginBottom}rem;
		`}

	${(props) =>
		props.marginLeft &&
		css`
			margin-left: ${({ marginLeft }) => marginLeft}rem;
		`}
`;

// #####################################################
